import ProgressBar from 'react-bootstrap/ProgressBar';
import Alert from 'react-bootstrap/Alert';
import { useState, useEffect } from "react";
import axios from 'axios';

function VoteResult({poll_id, is_ended, q1, q2, q3, q4, result}) {

  const [pollResult, setPollResult] = useState(is_ended ? JSON.parse(result) : {});

  useEffect(() => {
    if(!is_ended) {
      axios.get(`https://poll.nestiran.com/poll.php?action=fetch_vote&poll_id=${poll_id}`)
      .then(res => {
        setTimeout(() => setPollResult(res.data),2000);
      })
    }
  });

  let total = Object.values(pollResult).reduce((p,c) => p+c ,0);

  let props = {
    variant:"success",
    animated: false
  }

  if(!is_ended) {
     props['animated'] = true;
     //props['variant'] = "warning";
  }

  let livestatus;
  if(is_ended) {
    livestatus = <div className="vote-status">
    <Alert style={{marginTop:'20px',padding:'8px 15px',fontSize:'13px'}} variant="success">
      این نظر سنجی با مجموع   {total} رای به پایان رسیده است
    </Alert>
    </div>;
  }else{
    livestatus = <div className="vote-status">
    <Alert style={{marginTop:'20px',padding:'8px 15px',fontSize:'13px'}} variant="danger">
        درحال رای گیری ... مجموع رای تاکنون  {total}
    </Alert>
    </div>
  }
    


  return (
    <>
        { q1 &&
          <div className="result-row ">
            <div className="result-text">{q1}</div>
          <ProgressBar {...props}   now={(100 / total) * pollResult[1]} label={pollResult[1]}   />
          </div>
        }
        { q2 &&
          <div className="result-row">
            <div className="result-text">{q2}</div>
          <ProgressBar {...props}  now={(100 / total) * pollResult[2]} label={pollResult[2]}   />
          </div>
        }
        { q3 &&
          <div className="result-row">
            <div className="result-text">{q3}</div>
          <ProgressBar {...props}   now={(100 / total) * pollResult[3]} label={pollResult[3]}   />
          </div>
        }
        { q4 &&
          <div className="result-row">
            <div className="result-text">{q4}</div>
          <ProgressBar {...props}   now={(100 / total) * pollResult[4]} label={pollResult[4]}   />
          </div>
        }

       {livestatus}

    </>
  );
}

export default VoteResult;