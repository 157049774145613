import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import ThemeProvider from "react-bootstrap/ThemeProvider";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider dir="rtl">
    <div className="main-container">
      <Header />
      <App />
      <Footer />
    </div>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
