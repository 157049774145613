
import './assets/css/App.css'
import Container from 'react-bootstrap/Container';
import PanelList from './components/PanelList';
//http://poll.1969.ir/poll.php?action=end_poll&poll_id=2

function App() {

  return (
    // <Container >
        <PanelList />
    // </Container>
  );
}

export default App;
