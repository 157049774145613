import {useState} from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import VoteSubmitButton from './VoteSubmitButton';
import registry from './registry';

/* this project is really simple so a simple registry is enough instead of something like redux */
let reg = new registry();

function VotePanel({poll_id, q1, q2, q3, q4, setIsResult}) {
  const [isDisabled,setIsDisabled] = useState("disabled");


  let voteRequest = (poll_id) => {
    console.log(poll_id);

    axios.get('https://poll.nestiran.com/poll.php?action=vote&q='+reg.get('selectedQ')+'&poll_id='+poll_id+'&1002')
    .then(function (response) {
      console.log(response.data);
      setCookie('c' + poll_id, poll_id, 360);
      setIsResult(true);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <div style={{margin:'20px 10px 10px 0px'}}>
    <Form.Check key={`k1_${poll_id}`} type="radio"  id={`group1_${poll_id}`} name={`group${poll_id}`}  onChange={(e) => { reg.set('selectedQ','1'); setIsDisabled(''); }} value="q1" label={q1} />
    <Form.Check key={`k2_${poll_id}`} type="radio" id={`group2_${poll_id}`}  name={`group${poll_id}`}  onChange={(e) => { reg.set('selectedQ','2'); setIsDisabled('');  }} value="q2" label={q2} />
    <Form.Check key={`k3_${poll_id}`} type="radio" id={`group3_${poll_id}`}  name={`group${poll_id}`}  onChange={(e) => { reg.set('selectedQ','3'); setIsDisabled('');  }} value="q3" label={q3} />
    <Form.Check key={`k4_${poll_id}`} type="radio" id={`group4_${poll_id}`}  name={`group${poll_id}`}  onChange={(e) => { reg.set('selectedQ','4'); setIsDisabled('');  }} value="q4" label={q4} />
      <VoteSubmitButton isDisabled={isDisabled}  setIsResult={setIsResult} insertVote={(e) => voteRequest(poll_id)}  />
    </div>
  );
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export default VotePanel;