import { useState, useEffect } from "react";
import axios from 'axios';
import Panel from './Panel';

function PanelList() {

  const [polls, setPolls] = useState([]);
  
  useEffect(() => {
    axios.get(`https://poll.nestiran.com/poll.php?action=fetch_poll&event_id=1&32423`)
    .then(res => {
       setTimeout(()=>setPolls(res.data), 2000);
    })
  }); //, []

  return (
      <div style={{
        height:'85vh'
      }} className="overflow-y-scroll">
      {polls.map(function(poll, i){
          return <Panel key={`panel`+i} poll_id={poll.id} title={poll.title} q1={poll.q1} q2={poll.q2} q3={poll.q3} q4={poll.q4} result={poll.result} is_ended={poll.is_ended} />;
      })}
      </div>
    
  );
}

export default PanelList;