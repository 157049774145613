

class registry {

    constructor() {
        this._data = [];
    }

    get(name) {
        return this._data[name];
    }

    set(name,value) {
        this._data[name] = value;
    }
}



export default registry;
