import { useState } from "react";
import Card from "react-bootstrap/Card";
import VotePanel from "./VotePanel";
import VoteResult from "./VoteResult";
import "./Panel.css";
import clsx from "clsx";

function Panel({ poll_id, title, q1, q2, q3, q4, result, is_ended }) {
  const [isResult, setIsResult] = useState(parseInt(is_ended));

  return (
    <Card
      className={clsx("my-2", "poll-container")}
      style={{
        width: "100%",
        margin: "auto auto",
        backgroundColor: "rgba(255, 255, 255, 0.85) ",
      }}
    >
      <Card.Body style={{}}>
        <p
          className="fw-bold text-justify text-primary"
          dangerouslySetInnerHTML={{ __html: title }}
        ></p>
        {isResult || getCookie("c" + poll_id) === String(poll_id) ? (
          <VoteResult
            poll_id={poll_id}
            is_ended={parseInt(is_ended)}
            q1={q1}
            q2={q2}
            q3={q3}
            q4={q4}
            result={result}
          />
        ) : (
          <VotePanel
            poll_id={poll_id}
            q1={q1}
            q2={q2}
            q3={q3}
            q4={q4}
            setIsResult={setIsResult}
          />
        )}
      </Card.Body>
    </Card>
  );
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default Panel;
